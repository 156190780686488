export { wrapRootElement } from "./src/apollo/wrap-root-element";


export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log('new pathname', location.pathname)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
    // if (prevLocation && (prevLocation.pathname == '/' || prevLocation.pathname == '/buy-property/' || prevLocation.pathname == '/rent/')) {
      if (window)
        window.scroll({
          top: -100,
          behavior: 'smooth'
        });
    // }
  }